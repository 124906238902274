import React from 'react'
import { Container } from 'react-bootstrap'

import Layout from '../components/layout'
import CatalogModule from '../components/catalog/CatalogModule'
const Catalog = () => {
  return (
    <Layout title="Каталог" description="Каталог" brcr={['Каталог']}>
      <section className="catalog_page">
        <Container style={{ position: 'relative' }}>
          <h1>Каталог</h1>
          <CatalogModule withSearch />
        </Container>
      </section>
    </Layout>
  )
}

export default Catalog
